<template>
  <div id="basic-details" class="form-horizontal">
    <div class="title-bar">
        <div class="pull-right center-float" v-if="!editMode">
          <Button id="basicDetailsEditBtn" @click="editDetails" :label="$t(`edit_details`)">
          <i class="fa fa-fw fa-pencil"></i>
          </Button>
        </div>
        <div class="pull-right center-float" v-if="editMode">
          <Button id="basicDetailsCancelBtn" @click="cancel" color="secondary" :label="$t(`CancelChanges`)">
            <i class="fa fa-fw fa-ban"></i>
          </Button>
        </div>
      </div>
      <div v-if="editMode">
        <Form ref="form" :key="reload" :name="'BasicDetailsEdit'" :isEditing="editMode" :loadFormData="true" :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?episodeId=' + patientId" />
      </div>
      <div v-else>
        <Form ref="form" :key="reload" :name="'BasicDetails'" :isEditing="editMode" :loadFormData="true" :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?patientId=' + patientId" />
      </div>
  </div>

</template>

<script>
import Form from '../../../../shared/components/Form'
import EventBus from '@/app/shared/event-bus.js'
import Button from '../../../../shared/components/Button.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',
  data: self => ({
    editMode: false,
    reload: 0
  }),
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  destroyed () {
    EventBus.$off('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  components: {
    Form,
    Button
  },
  computed: {
    ...mapState('Form', ['isLoading']),
    ...mapState('Patient', ['patientId'])
  },
  methods: {
    editDetails () {
      if (this.isLoading) {
        return
      }
      this.editMode = true
      this.reload += 1
    },
    cancel () {
      if (this.isLoading) {
        return
      }
      this.editMode = false
      this.reload += 1
    },
    formSavedSuccessfully () {
      this.editMode = false
      location.reload()
      this.getMermDetails()
    },
    ...mapActions('MERM', ['getMermDetails'])
  }
}
</script>

<style scoped>
.form-horizontal {
  background-color: var(--background-color-primary-light-dark);
  padding: 15px 0;
}
#basicDetailsEditBtn, #basicDetailsCancelBtn {
  margin-right: 18px;
}
.pull-right {
  justify-content: flex-end;
  display: flex;
}
.text-no-margin {
  margin: auto;
}

* {
  margin: revert;
  box-sizing: unset;
}

</style>
